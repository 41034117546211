import React, { useState } from 'react'
import Inview from 'components/Inview'
import LocationsGrid from 'components/LocationsGrid'

const Component = ({ cities, locations, thisLocation }) => {
  cities = cities.map((city) => {
    return {
      slug: city.uid,
      title: city.data.title.text,
    }
  })
  locations = locations.map((location) => {
    return {
      city: {
        slug: location.data.city.uid,
      },
      slug: location.uid,
      title: location.data.title.text,
      excerpt: location.data.excerpt.text,
      gatsbyImage: location.data.image,
    }
  })
  let moreLocations = []

  // same city but not the current location
  const thisCityLocations = locations.filter(
    (location) =>
      location.city.slug === thisLocation.data.city.uid &&
      location.slug !== thisLocation.uid
  )
  // other cities
  const otherCityLocations = locations.filter(
    (location) => location.city.slug !== thisLocation.data.city.uid
  )
  moreLocations = moreLocations.concat(thisCityLocations)
  moreLocations = moreLocations.concat(otherCityLocations)
  moreLocations = moreLocations.slice(0, 3)

  return (
    <section className='block-more-locations bg--grey-2 pt-20 md:pt-24 lg:pt-32 pb-20 md:pb-24 lg:pb-32'>
      <div className='container relative z-10'>
        <Inview className='text fade-in'>
          <h1 className='h1 smaller'>其他门店</h1>
        </Inview>
        <div className='locations'>
          <LocationsGrid locations={moreLocations} />
        </div>
      </div>
    </section>
  )
}

export default Component
