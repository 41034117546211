import React from 'react'
import { graphql } from 'gatsby'
import BlockHero from 'components/blocks/BlockHero'
import BlockTextOnly from 'components/blocks/BlockTextOnly'
import BlockBgImageBox from 'components/blocks/BlockBgImageBox'
import BlockSlideshow from 'components/blocks/BlockSlideshow'
import BlockLocationMap from 'components/blocks/BlockLocationMap'
import BlockSpecs from 'components/blocks/BlockSpecs'
import BlockMoreLocations from 'components/blocks/BlockMoreLocations'
import BlockContact from 'components/blocks/BlockContact'
import Footer from 'components/Footer'
import Seo from 'components/Seo'

// markup
const Page = ({ data }) => {
  const cities = data.allPrismicCity.nodes
  const locations = data.allPrismicLocation.nodes
  const thisLocation = data.prismicLocation
  const content = {
    blockHero: {
      gatsbyImage: thisLocation.data.banner_image,
      textBlock: {
        isDark: true,
        hasContactButton: true,
        text: {
          title: thisLocation.data.banner_title.text,
          highlight: thisLocation.data.banner_highlight.text,
          description: thisLocation.data.banner_excerpt.text,
        },
      },
      hasReturnButton: true,
    },
    blockTextOnly: {
      pretitle: `选址优势`,
      title: thisLocation.data.text_title.text,
      body: thisLocation.data.text_body,
      hasContactButton: true,
    },
    blockBgImageBox: {
      gatsbyImage: thisLocation.data.box_image,
      textBox: {
        title: thisLocation.data.box_title.text,
        listHtml: thisLocation.data.box_list,
        note: thisLocation.data.box_note.text,
      },
    },
    blockSlideshow: {
      images: thisLocation.data.slideshow_images,
    },
    blockLocationMap: {
      image: 'map_shanghai-1.png',
      gatsbyImage: thisLocation.data.map_image,
      topData: [
        {
          a: thisLocation.data.map_value_1.text,
          b: thisLocation.data.map_subtitle_1.text,
          c: thisLocation.data.map_excerpt_1.text,
        },
        {
          a: thisLocation.data.map_value_2.text,
          b: thisLocation.data.map_subtitle_2.text,
          c: thisLocation.data.map_excerpt_2.text,
        },
      ],
      tableRows: thisLocation.data.map_table.map((row) => {
        return {
          label: row.map_table_label.text,
          value1: row.map_table_value_1.text,
          value2: row.map_table_value_2.text,
        }
      }),
    },
    blockSpecs: {
      services: thisLocation.data.specs_specs.map((item) => {
        return {
          title: item.specs_specs_title.text,
          detailsHtml: item.specs_specs_list,
        }
      }),
    },
  }
  const seoContent = {
    title: `店铺地图 - ${thisLocation.data.title.text}`,
  }

  return (
    <div>
      <Seo content={seoContent} />
      <BlockHero
        content={content.blockHero}
        mobileBackgroundClass='blue-to-grey'
      />
      <BlockTextOnly content={content.blockTextOnly} />
      <BlockBgImageBox content={content.blockBgImageBox} />
      <BlockSlideshow content={content.blockSlideshow} />
      <BlockLocationMap content={content.blockLocationMap} />
      <BlockSpecs content={content.blockSpecs} isInner={true} />
      <BlockMoreLocations
        cities={cities}
        locations={locations}
        thisLocation={thisLocation}
      />
      <BlockContact />
      <Footer />
    </div>
  )
}

export default Page

export const query = graphql`
  query ($uid: String!) {
    prismicLocation(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        city {
          uid
        }
        banner_image {
          gatsbyImageData
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        banner_title {
          text
        }
        banner_highlight {
          text
        }
        banner_excerpt {
          text
        }
        text_title {
          text
        }
        text_body {
          richText
        }
        box_image {
          gatsbyImageData
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        box_title {
          text
        }
        box_list {
          richText
        }
        box_note {
          text
        }
        slideshow_images {
          slideshow_images_image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        map_image {
          gatsbyImageData
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        map_value_1 {
          text
        }
        map_subtitle_1 {
          text
        }
        map_excerpt_1 {
          text
        }
        map_value_2 {
          text
        }
        map_subtitle_2 {
          text
        }
        map_excerpt_2 {
          text
        }
        map_table {
          map_table_label {
            text
          }
          map_table_value_1 {
            text
          }
          map_table_value_2 {
            text
          }
        }
        specs_specs {
          specs_specs_title {
            text
          }
          specs_specs_list {
            richText
          }
        }
      }
    }
    allPrismicCity(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        uid
        data {
          title {
            text
          }
        }
      }
    }
    allPrismicLocation(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          city {
            uid
          }
        }
      }
    }
  }
`
