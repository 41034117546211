import React from 'react'
import ButtonContact from 'components/ButtonContact'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  const { gatsbyImage, topData, tableRows } = content
  return (
    <section className='block-location-map bg--grey-2 pb-16 sm:pb-28 lg:pb-48'>
      <div className='map'>
        <Inview className='image fade-in'>
          <ImageWrap image={gatsbyImage} />
        </Inview>
        <Inview className='container fade-in-up'>
          {/* {gatsbyImage.localFile && (
            <div className='mt-8 hidden lg:block'>
              <ButtonContact />
            </div>
          )} */}
        </Inview>
      </div>
      <div className='text'>
        <div className='container'>
          <Inview className='inner fade-in-up'>
            <div className='grid grid-cols-12'>
              <div
                className='
              col-span-12 sm:col-start-1 
              lg:col-span-7 lg:col-start-6 
              sm:grid sm:grid-cols-7 sm:pt-20
              bg--grey-2
              '
              >
                <div className='sm:col-start-2 sm:col-span-5 lg:col-start-2 lg:col-span-6'>
                  <div className='top'>
                    {topData.map((column, i) => (
                      <div key={i} className='column'>
                        <h3 className='h3 smaller'>{column.a}</h3>
                        <h5 className='h6 mt-1'>{column.b}</h5>
                        <p className='small pt-3'>{column.c}</p>
                      </div>
                    ))}
                  </div>
                  <div className='table'>
                    <div className='th'>
                      <div className='td header'></div>
                      <div className='td header'>
                        骑行范围
                        <br className='sm:hidden' />
                        3公里内
                      </div>
                      <div className='td header'>
                        直线距离
                        <br className='sm:hidden' />
                        3公里内
                      </div>
                    </div>
                    {tableRows.map((row, i) => (
                      <div key={i} className='tr'>
                        <div className='td label'>{row.label}</div>
                        <div className='td'>{row.value1}</div>
                        <div className='td'>{row.value2}</div>
                      </div>
                    ))}
                  </div>
                  {gatsbyImage.localFile && (
                    <div className='mt-16 lg:hidden'>
                      <ButtonContact />
                    </div>
                  )}
                </div>
              </div>
              {gatsbyImage.localFile && (
                <div className='button-contact-desktop hidden lg:block lg:col-span-5'>
                  <ButtonContact />
                </div>
              )}
            </div>
          </Inview>
        </div>
      </div>
    </section>
  )
}

export default Component
