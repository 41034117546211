import React from 'react'
import Image from 'components/Image'
import ImageWrap from 'components/ImageWrap'
import BodyWrap from 'components/BodyWrap'
import Inview from 'components/Inview'

const Component = ({ content }) => {
  const { image, gatsbyImage, textBox } = content
  return (
    <section className='block-bg-image-box'>
      <Inview className='bg-image zoom' triggerOnce={true}>
        {image && <Image filename={image} />}
        {gatsbyImage && <ImageWrap image={gatsbyImage} />}
      </Inview>
      <div className='container pt-20 md:pt-24 lg:pt-40 pb-20 md:pb-24 lg:pb-40'>
        <div className='grid grid-cols-12'>
          <Inview className='box col-span-12 sm:col-span-8 lg:col-span-6 lg:col-start-6 bg--primary color--white sm:grid sm:grid-cols-6 sm:pt-14 sm:pb-14'>
            <div className='box-bg bg--primary' />
            <div className='box-inner sm:col-span-4 sm:col-start-2 fade-in-up'>
              <h3 className='h5'>{textBox.title}</h3>
              <hr className='mt-3 mb-5' />
              <div className='ul--specs'>
                {textBox.list && (
                  <ul>
                    {textBox.list.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                )}
                {textBox.listHtml && <BodyWrap body={textBox.listHtml} />}
              </div>
              {textBox.note && (
                <div className='mt-6'>
                  <p
                    className='small'
                    dangerouslySetInnerHTML={{ __html: textBox.note }}
                  ></p>
                </div>
              )}
            </div>
          </Inview>
        </div>
      </div>
    </section>
  )
}

export default Component
