import React from 'react'
import ImageWrap from 'components/ImageWrap'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import Inview from 'components/Inview'

SwiperCore.use([Autoplay, Navigation])

const Component = ({ content }) => {
  const { images } = content
  return (
    <section className='block-slideshow bg--grey-2'>
      <div className='container pt-20 pb-32 md:pb-24 md:pt-36 lg:pt-40 lg:pb-48'>
        {/* text + select */}
        <div className='grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
          <div className='col-span-2'>
            <div className='top-160'>
              <Inview>
                <div className='eyebrow p5 line color--grey-4 mb-10 lg:mb-0 fade-in-up'>
                  实际场地
                </div>
              </Inview>
            </div>
          </div>
          <div className='col-span-4 md:col-span-8 lg:col-span-10'>
            <Inview>
              <div className='grid grid-cols-4 md:grid-cols-8 lg:grid-cols-10 fade-in-up '>
                <div className='col-span-4 md:col-span-8 lg:col-span-12'>
                  <h2 className='h2 color--primary mb-2 md:mb-0'>
                    看看我们的场地
                  </h2>
                </div>
              </div>
            </Inview>
          </div>
        </div>
        {/* sliders */}
        <Inview>
          <div
            className={`grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 mt-10 md:mt-16 lg:mt-20 fade-in-up`}
          >
            <div className='col-span-4 md:col-span-8 lg:col-start-3'>
              <div className='swiper-wrap'>
                <SwiperComponent images={images} />
              </div>
            </div>
          </div>
        </Inview>
      </div>
    </section>
  )
}

export default Component

class SwiperComponent extends React.Component {
  render() {
    const { images } = this.props
    return (
      <div className={`swiper-component active`}>
        <div className='swiper-nav mb-4'>
          <div className={`swiper-nav-item swiper-prev`}>
            <div className={`arrow arrow-bar is-left`} />
          </div>
          <div className={`swiper-nav-item swiper-next`}>
            <div className={`arrow arrow-bar is-right`} />
          </div>
        </div>
        <Swiper
          speed={600}
          navigation={{
            nextEl: `.swiper-next`,
            prevEl: `.swiper-prev`,
          }}
          // autoplay={{
          //   delay: 4000,
          // }}
          loop={true}
          loopedSlides={images.length}
          loopAdditionalSlides={1}
          slideToClickedSlide={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 15,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
        >
          {images.map((image, i) => (
            <SwiperSlide key={i}>
              <Slide image={image.slideshow_images_image} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }
}

const Slide = ({ image }) => {
  return (
    <div className='col-span-4 md:col-span-2 lg:col-span-4 image-wrap'>
      <ImageWrap image={image} />
    </div>
  )
}
